import React, {useRef } from 'react';
import emailjs from '@emailjs/browser';
import { InputMask } from 'primereact/inputmask';

const Breef = ({ Header }) => {
const form = useRef();

const sendEmail = (e) => { e.preventDefault();

        emailjs.sendForm('service_r6hd8dj', 'template_qza8o5g', form.current, '849U7A17MB36hjmvQ')
            .then((result) => {
                console.log(result.text);
                console.log("message sent!")
				form.current.reset();
            }, (error) => {
                console.log(error.text);
                console.log("error sending message, try again!")
            });
        };
 
  return (
     <div className="hero-wrap">
	 <div className="hero-mask">
	 </div>
	 <div className="hero-content section  d-flex align-items-center min-vh-100">
	 <div className="container">
	 <div className="lc-block text-center mb-4 mt-5">
                    <h2 editable="inline" className="display-2 "><b>Заполнить бриф</b></h2>
                </div>
		
		
     <form className="form_breef" ref={form} onSubmit={sendEmail}>
	 <div className="label">
            Выберите тип проекта:
          </div>
	 <div className="project_types">
		<div className="project_types__line">			
			<div className="checkbox_button">
				<label>
					<input type="checkbox" name="projectTypes" value="Сайт-визитка под ключ"/>
						<span>
						Сайт-визитка под ключ
						</span>
				</label>
			</div>			
		</div>

		<div className="project_types__line">			
			<div className="checkbox_button">
				<label>
					<input type="checkbox" name="projectTypes" value="Landing-page"/>
						<span>
						Landing-page
						</span>
				</label>
			</div>			
		</div>
		
		<div className="project_types__line">			
			<div className="checkbox_button">
				<label>
					<input type="checkbox" name="projectTypes" value="Интернет-магазин"/>
						<span>
						Интернет-магазин
						</span>
				</label>
			</div>			
		</div>
		
		
		<div className="project_types__line">			
			<div className="checkbox_button">
				<label>
					<input type="checkbox" name="projectTypes" value="Корпоративный сайт"/>
						<span>
						Корпоративный сайт
						</span>
				</label>
			</div>			
		</div>
		
		<div className="project_types__line">			
			<div className="checkbox_button">
				<label>
					<input type="checkbox" name="projectTypes" value="Мобильное приложение"/>
						<span>
						Мобильное приложение
						</span>
				</label>
			</div>			
		</div>
		
		<div className="project_types__line">			
			<div className="checkbox_button">
				<label>
					<input type="checkbox" name="projectTypes" value="Разработка логотипа"/>
						<span>
						Разработка логотипа
						</span>
				</label>
			</div>			
		</div>
		
		
		<div className="project_types__line">			
			<div className="checkbox_button">
				<label>
					<input type="checkbox" name="projectTypes" value="Веб-дизайн"/>
						<span>
						Веб-дизайн
						</span>
				</label>
			</div>			
		</div>
		
		
		<div className="project_types__line">			
			<div className="checkbox_button">
				<label>
					<input type="checkbox" name="projectTypes" value="SEO-продвижение"/>
						<span>
						SEO-продвижение
						</span>
				</label>
			</div>			
		</div>
	</div>
	
		<div className="label">
            Ваш бюджет:
        </div>
		
		<div className="project_types">
			<div className="project_types__line">			
				<div className="checkbox_button">
					<label>
						<input type="checkbox" name="projectTypes" value="50000rub"/>
							<span>
							до 50 000 ₽
							</span>
					</label>
				</div>			
			</div>
			
			<div className="project_types__line">			
				<div className="checkbox_button">
					<label>
						<input type="checkbox" name="projectTypes" value="70000rub"/>
							<span>
							 50 000 - 100 000 ₽
							</span>
					</label>
				</div>			
			</div>
			
			
			<div className="project_types__line">			
				<div className="checkbox_button">
					<label>
						<input type="checkbox" name="projectTypes" value="70000rub"/>
							<span>
							 100 000 - 300 000 ₽
							</span>
					</label>
				</div>			
			</div>
			
			
			<div className="project_types__line">			
				<div className="checkbox_button">
					<label>
						<input type="checkbox" name="projectTypes" value="70000rub"/>
							<span>
							 300 000 - 1 000 000 ₽
							</span>
					</label>
				</div>			
			</div>
		</div>
	
	<div className="label">
            Опишите задачу:
    </div>
	
	<div title="Введите описание от 10 символов" className="textarea">
		<textarea required="required" placeholder="Описание проекта" name="" rows="10" cols={10}></textarea>
	</div>
	
	<div className="label">
           Ваши контактные данные:
    </div>
	
	<div className="project_types">
	 
		<div className="project_types__line">
			<InputMask required="required" id="phone" mask="+7 (999) 999-99-99" placeholder="Ваш телефон"/>
		</div>
	
		<div className="project_types__line">	
			<input required="required" name='user_name' type="name" placeholder='Ваше имя' required/>
		</div>
		
		<div className="project_types__line">	
			<input  name='website' type="text" placeholder='Ссылка на сайт' required/>
		</div>
	
		<div className="project_types__line">	
			<input name='website' type="email" placeholder='Email' required/>
		</div>	
	
	 </div>
	 <div className="check_radio">
	 <div className="checkbox" >
	 <label>
	 <input type="checkbox" hidden="hidden"/>
	 <div className="box">
	 </div>
	 <span>Согласен с <a href="#" target="_blank">условиями обработки персональных данных</a></span>
	 </label>
	 </div>
	 </div>
			
			
			
			<div className="actions">
			<button className="button_breef" type="submit">Отправить</button>		
			</div>
			
			
			</form>
	  
    </div>
	</div>
	</div>
  );
};

export default Breef;
