import React, { useState } from "react";
import {Link} from 'react-router-dom';
import Logo from  './logodark.svg';

const ClassicHeader = ({ handleNavClick }) => {
 
  const [isNavModalClose, setIsNavModalClose] = useState(true);
 const [stickyHeader, setStickyHeader] = useState(false);

  const checkScrollTop = () => {
    let header = document.getElementsByClassName("primary-menu");

    if (header) {
      if (
        document.body.scrollTop > 10 ||
        document.documentElement.scrollTop > 10
      ) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    }
  };
    if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <header id="header" className="sticky-top-slide">
      {/* Navbar */}
      <nav
        className={
          "primary-menu navbar navbar-expand-lg  navbar-dark border-bottom-0 sticky-top "+
          (stickyHeader ? "sticky-on" : "")
        }>
		<div className="col-auto">
            {/* Logo */}
            <Link to="/"className="mb-lg-auto  logo ">
              <div className="logosvg mb-lg-1  d-lg-inline-block">
      <img className="mask" alt="logo" src={Logo}/> 
    </div>
          </Link>
            {/* Logo End */}
          </div>
        <div className="container-fluids  justify-content-start g-lg-4">

          <div className="navbar-accordion">
            <button
				
              onClick={(e) => {
                setIsNavModalClose(!isNavModalClose);
              }}
              className={
                isNavModalClose
                  ? "navbar-toggler ms-auto"
                  : "navbar-toggler ms-auto show"
              }
			  aria-label="navbar"
              id="navbar-toggler"
              type="button"
			  
            >
             <span/>
             <span/>
             <span/>
			
            </button>
            <div
              id="header-nav"
              className={
                isNavModalClose
                  ? "collapse navbar-collapse"
                  : "show navbar-collapse"
              }
            >
              <ul className="navbar-nav text-lg-center my-lg-auto">
              <li className="nav-item">
                 <Link  className="nav-link" to="/portfolio" 
				 onClick={(e) => {
                setIsNavModalClose(true);
              }}>Кейсы</Link>
              </li>
               <li className="nav-item">
                 <Link className="nav-link" to="/services" onClick={(e) => {
                setIsNavModalClose(true);
              }}>Услуги</Link>
              </li>
			   <li className="nav-item">
                 <Link className="nav-link" to="/contact" onClick={(e) => {

                setIsNavModalClose(true);
              }}>Контакты</Link>
              </li>

   


            </ul>
            </div>
          </div>

	
        </div>
		
				 
		          

      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default ClassicHeader;
