import React, { useState } from "react";
import {Link} from 'react-router-dom';
import Logo from  './logodark.svg';

const Header = ({ Header, darkTheme, homeRef, handleNavClick }) => {
  const [isNavModalClose, setIsNavModalClose] = useState(true);
  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg navbar-dark border-bottom-0">
        <div className="container-fluid position-relative h-100 flex-lg-column ps-3 px-lg-0 pt-lg-3 pb-lg-2">
          {/* Logo */}
          <Link to="/"className="mb-lg-auto mt-lg-4">
              <div className="logosvg">
     <img className="mask" alt="logo" src={Logo}/>
    </div> 
          </Link>
          {/* Logo End */}
          <div
            id="header-nav"
            className={
              isNavModalClose
                ? "collapse navbar-collapse w-100 my-lg-auto "
                : "show navbar-collapse w-100 my-lg-auto"
            }
          >
            <ul className="navbar-nav text-lg-center my-lg-auto py-lg-3">
              <li className="nav-item">
                 <Link className="nav-link" to="/portfolio">Кейсы</Link>
              </li>
               <li className="nav-item">
                 <Link className="nav-link" to="/services">Цены</Link>
              </li>
			   <li className="nav-item">
                 <Link className="nav-link" to="/">Контакты</Link>
              </li>

   


            </ul>
          </div>
		  
          
          <button
            onClick={(e) => {
              setIsNavModalClose(!isNavModalClose);
            }}
            className={
              isNavModalClose ? "navbar-toggler" : "navbar-toggler show"
            }
            id="navbar-toggler"
            type="button"
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default Header;
