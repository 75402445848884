import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { InputMask } from 'primereact/inputmask';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Logo from  './logodark.svg';
import Phone from './header_4.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'font-awesome/css/font-awesome.min.css';



const Home = ({ classicHeader,  handleNavClick }) => {
const [visible, setVisible] = useState(false);
const form = useRef();
const [overflow, setOverflow] = useState(false); 
const sendEmail = (e) => { e.preventDefault();

        emailjs.sendForm('service_r6hd8dj', 'template_qza8o5g', form.current, '849U7A17MB36hjmvQ')
            .then((result) => {
                console.log(result.text);
                console.log("message sent!")
				form.current.reset();
            }, (error) => {
                console.log(error.text);
                console.log("error sending message, try again!")
            });
        };

		
  return (
  <>
  
    <section id="home">
      <div className="hero-wrap">
		<Dialog visible={visible}  modal className="card-modal flex justify-content-center" visible={visible} onHide={() => {if (!visible) return; setVisible(false); }}>
                <p className="feedback_modal_title"> Связаться с нами </p>
					<p className="feedback_modal_p">Оставьте свои контакты и наш менеджер свяжется с вами. Или позвоните по номеру 
					<a className="tel" href="tel:89883039889"> +7 988 303 98 89 </a> </p>
						<form ref={form} onSubmit={sendEmail}>
							<input name='user_name' type="name" placeholder='ваше имя' required/>
							<InputMask name='user_phone' mask="+7 (999) 999-99-99" placeholder="ваш телефон"/>
							<button className="submitButton" type="submit">Отправить</button>
						</form>
        </Dialog>	


<section class="position-relative headbg">
    <div class="hero-content section d-flex min-vh-100">
        <div class="container my-auto">
            <div class="row align-items-start mt-8">
                <div class="col-lg-5 order col-md-6">
                    <div class="heading-title">
                        <h1 class=" fw-bold">Разработка сайтов под ключ <br /></h1>
                        <p class="text-muted para-desc mb-0">Разрабатываем лендинги, корпоративные сайты, интернет-магазины, сайты-визитки. Адаптируем готовые сайты под планшеты и смартфоны.</p>
                        <div class="watch-video mt-4 pt-2">
                            <button class="btn_blue" label="Show">Заказать сайт</button>
                            <a class="lightbox video-play-icon watch text-dark ms-2" href="/index-developer">
                                <i class="mdi mdi-play play-icon-circle text-center d-inline-block me-2 rounded-circle text-white position-relative play play-iconbar"></i> Написать нам
                            </a>
                        </div>
                    </div>
					
                </div>
<div className="col-lg-6">
  <div className="img ipad">
    <img class="" alt="logo" src={Phone}/>
  </div>
</div>

				</div>
				
            </div>	
			
        </div>	


</section>	
</div>
</section>





				
</>
  );
  
};

export default Home;
