// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gilroy-Bold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gilroy-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Gilroy-Regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/Gilroy-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/Gilroy-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./fonts/Gilroy-SemiBold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./fonts/Gilroy-SemiBold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("./fonts/Gilroy-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("./fonts/Jost-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_10___ = new URL("./fonts/Jost-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_11___ = new URL("./fonts/Jost-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_12___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Gilroy";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Gilroy";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}), url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
  src: local("/fonts/Gilroy SemiBold"), local("Gilroy-SemiBold"), url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format("embedded-opentype"), url(${___CSS_LOADER_URL_REPLACEMENT_8___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_9___}) format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Jost";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_10___}) format("woff2"), url(${___CSS_LOADER_URL_REPLACEMENT_11___}) format("woff"), url(${___CSS_LOADER_URL_REPLACEMENT_12___}) format("truetype");
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,gKAAA;EAGK,gBAAA;AADP;AAIA;EACE,qBAAA;EACA,gKAAA;EAGE,gBAAA;AAJJ;AAMA;EACI,qBAAA;EACA,4CAAA;EACA,uPAAA;EAIA,gBAAA;EACA,kBAAA;AAPJ;AAUA;EACC,mBAAA;EACA,mLACA;EAGA,gBAAA;AAXD","sourcesContent":["@font-face {\r\n  font-family: 'Gilroy';\r\n  src: url('/fonts/Gilroy-Bold.eot'),\r\n       url('/fonts/Gilroy-Bold.woff') format('woff'), \r\n       url('/fonts/Gilroy-Bold.ttf')  format('truetype');  \r\n\t      font-weight: 800;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Gilroy';\r\n  src: url('/fonts/Gilroy-Regular.eot'),\r\n       url('/fonts/Gilroy-Regular.woff') format('woff'), \r\n       url('/fonts/Gilroy-Regular.ttf')  format('truetype');  \r\n\t   font-weight: 500;\r\n}\r\n@font-face {\r\n    font-family: 'Gilroy';\r\n    src: url('/fonts/Gilroy-SemiBold.eot');\r\n    src: local('/fonts/Gilroy SemiBold'), local('Gilroy-SemiBold'),\r\n        url('/fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),\r\n        url('/fonts/Gilroy-SemiBold.woff') format('woff'),\r\n        url('/fonts/Gilroy-SemiBold.ttf') format('truetype');\r\n    font-weight: 600;\r\n    font-style: normal;\r\n}\r\n\r\n@font-face {\r\n\tfont-family: 'Jost';\r\n\tsrc:\r\n\turl('/fonts/Jost-Regular.woff2') format('woff2'),\r\n\turl('/fonts/Jost-Regular.woff') format('woff'),\r\n\turl('/fonts/Jost-Regular.ttf')  format('truetype'); \r\n\tfont-weight: 400;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
