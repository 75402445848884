import React from "react";
import "react-toastify/dist/ReactToastify.css";

const Contact = ({ Header, darkTheme }) => {

  return (
	<div className="hero-wrap">
	 <div className="hero-mask"></div>
	 <div className="hero-content section  d-flex align-items-center min-vh-100">
	 <div className="container">
	 <div className="lc-block text-center mb-4 mt-4">
                    <h2 editable="inline" className="display-2 "><b>Контакты</b></h2>
                </div>
 <div className="row row-cols-1 row-cols-lg-1 align-items-stretch g-4 py-5">
		<div className="contacts__wrapper">
			<div className="contacts__grid">
			
				<div className="contact">
					<div className="contact__heading">
						<h3 className="contact__title montserrat-20">Телефон</h3>
					</div>
					<div className="contact__main">
						<a href="tel:79883039889">+7 (988) 303-98-89</a>					
					</div>
					
				</div>
				
				
				<div className="contact">
				
					<div className="contact__heading">
					<h3 className="contact__title montserrat-20">WhatsApp</h3>
					</div>
					
					<div className="contact__main">
						<a href="https://wa.clck.bar/79883039889" rel="noreferrer">+7 (988) 303-98-89</a>
					</div>
					<p className="contact__desc montserrat-20">пишите круглосуточно</p>
				</div>
				
				
				
				<div className="contact">
					<div className="contact__heading">
						<h3 className="contact__title montserrat-20">Офис</h3>
					</div>
					<div className="contact__main contact__main_address">
						<a href="#" rel="noreferrer">г. Хасавюрт, ул. Алиева 21д</a>
					</div>
					
				</div>
				
				<div className="contact">
					<div className="contact__heading">
						<h3 className="contact__title montserrat-20">Email</h3>
					</div>
					<div className="contact__main">
						<a href="mailto:collibryweb@mail.ru" rel="noreferrer">collibryweb@mail.ru</a>
					</div>
					<p className="contact__desc montserrat-20">напишите на почту</p>
				</div>
				
				
		
		</div>

		<div className="contacts__bottom-wrapper">
			

			 
			<ul className="social-with-arrow">
			<li>
			<a className="montserrat-18 social-with-arrow__item" rel="noreferrer" href="https://t.me/collibryru">
				telegram				<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
					<path d="M0.292893 9.29289C-0.0976311 9.68342 -0.0976311 10.3166 0.292893 10.7071C0.683418 11.0976 1.31658 11.0976 1.70711 10.7071L0.292893 9.29289ZM11 1C11 0.447715 10.5523 -3.74211e-07 10 4.72575e-08L1 -1.63477e-07C0.447715 -1.63477e-07 2.8711e-07 0.447715 2.8711e-07 1C2.8711e-07 1.55228 0.447715 2 1 2L9 2L9 10C9 10.5523 9.44772 11 10 11C10.5523 11 11 10.5523 11 10L11 1ZM1.70711 10.7071L10.7071 1.70711L9.29289 0.292893L0.292893 9.29289L1.70711 10.7071Z" fill="black" fill-opacity="0.39"></path>
				</svg>
			</a>
		</li>
			<li>
			<a className="montserrat-18 social-with-arrow__item" rel="noreferrer" href="https://vk.com/collibryru">
				vkontakte				<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
					<path d="M0.292893 9.29289C-0.0976311 9.68342 -0.0976311 10.3166 0.292893 10.7071C0.683418 11.0976 1.31658 11.0976 1.70711 10.7071L0.292893 9.29289ZM11 1C11 0.447715 10.5523 -3.74211e-07 10 4.72575e-08L1 -1.63477e-07C0.447715 -1.63477e-07 2.8711e-07 0.447715 2.8711e-07 1C2.8711e-07 1.55228 0.447715 2 1 2L9 2L9 10C9 10.5523 9.44772 11 10 11C10.5523 11 11 10.5523 11 10L11 1ZM1.70711 10.7071L10.7071 1.70711L9.29289 0.292893L0.292893 9.29289L1.70711 10.7071Z" fill="black" fill-opacity="0.39"></path>
				</svg>
			</a>
		</li>
			<li>
			<a className="montserrat-18 social-with-arrow__item" rel="noreferrer" href="https://youtube.com/collibryru">
				youtube				<svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
					<path d="M0.292893 9.29289C-0.0976311 9.68342 -0.0976311 10.3166 0.292893 10.7071C0.683418 11.0976 1.31658 11.0976 1.70711 10.7071L0.292893 9.29289ZM11 1C11 0.447715 10.5523 -3.74211e-07 10 4.72575e-08L1 -1.63477e-07C0.447715 -1.63477e-07 2.8711e-07 0.447715 2.8711e-07 1C2.8711e-07 1.55228 0.447715 2 1 2L9 2L9 10C9 10.5523 9.44772 11 10 11C10.5523 11 11 10.5523 11 10L11 1ZM1.70711 10.7071L10.7071 1.70711L9.29289 0.292893L0.292893 9.29289L1.70711 10.7071Z" fill="black" fill-opacity="0.39"></path>
				</svg>
			</a>
		</li>
			
	</ul>
		</div>
		</div>
	</div>
</div>
</div>
</div>
  );
};

export default Contact;
