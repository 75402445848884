import "./App.scss";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Breef from "./components/Breef";
import Header from "./components/Header";
import ClassicHeader from "./components/ClassicHeader";
import { commonConfig } from "./config/commonConfig";
import {Routes, Route } from "react-router-dom";

function App() {
  const classicHeader = commonConfig.classicHeader;

  return (
    <>
      <div
        style={{ position: "relative" }}
        className={classicHeader ? "" : "side-header"}
      >

        <div id="main-wrapper">
          {Header}

          <div id="content" role="main">
		  <Header/>
          <Routes>
   <Route path="/" element={<Home />} />

   <Route path="/portfolio" element={<Portfolio/>} />
   <Route path="/services" element={<Services/>} />
   <Route path="/contact" element={<Contact/>} />
   
</Routes>
          </div>
		  
        </div>
      </div>
	 
    </>
  );
}

export default App;
