import React from "react";


const Portfolio = ({ Header, darkTheme }) => {
  
  return (
    <>
	<div className="hero-wrap">
		<div className="hero-content section   align-items-center min-vh-100">
			<div className="rows">
				<section className="case-portf">
					<div className="container">
						<div className="for-portf-bg">
							<div className="line-portf"/>
							<div className="row">
								<div className="col-md-8">
									<div className="portf-case">
										<img className="img-fluid" alt="project1" src="images/projects/project1.jpg"/>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card-portf-case">
										<div className="mini-tags">
											<span>Сайт организации</span>
										</div>
										<div className="h2-zg">
											<h2>Хасавюртовская ЦГБ</h2>
											<p>Сайт медицинской организации</p>
										</div>
										<a className="button-case"  target="_blanc" href="http://hascgb.ru">Перейти</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="case-portf">
						<div className="container">
							<div className="for-portf-bg">
								<div className="line-portf"/>
								<div className="row">
									<div className="col-md-8">
										<div className="portf-case">
											<img className="img-fluid" alt="project2" src="images/projects/project-2.jpg"/>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card-portf-case">
											<div className="mini-tags">
												<span>Сайт организации</span>
											</div>
											<div className="h2-zg">
												<h2>Айдент</h2>
												<p>Сайт стоматологической клиники</p>
											</div>
											<a className="button-case"  target="_blanc" href="http://aydent05.ru">Перейти</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="case-portf">
							<div className="container">
								<div className="for-portf-bg">
									<div className="line-portf"/>
									<div className="row">
										<div className="col-md-8">
											<div className="portf-case">
												<img className="img-fluid" alt="project3" src="images/projects/project-3.jpg"/>
											</div>
										</div>
										<div className="col-md-4">
											<div className="card-portf-case">
												<div className="mini-tags">
													<span>Сайт каталог</span>
												</div>
												<div className="h2-zg">
													<h2>Tayra</h2>
													<p>Сайт каталог товаров </p>
												</div>
												<a className="button-case"  target="_blanc" href="http://tayrasrls.com">Перейти</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</section>
							<section className="case-portf">
								<div className="container">
									<div className="for-portf-bg">
										<div className="line-portf"/>
										<div className="row">
											<div className="col-md-8">
												<div className="portf-case">
													<img className="img-fluid"  alt="project4" src="images/projects/project-4.jpg"/>
												</div>
											</div>
											<div className="col-md-4">
												<div className="card-portf-case">
													<div className="mini-tags">
														<span>Сайт организации</span>
													</div>
													<div className="h2-zg">
														<h2>Перинатальный центр</h2>
														<p>Сайт медицинской организации</p>
													</div>
													<a className="button-case"  target="_blanc" href="http://perinatal05.ru">Перейти</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>
								<section className="case-portf">
									<div className="container">
										<div className="for-portf-bg">
											<div className="line-portf"/>
											<div className="row">
												<div className="col-md-8">
													<div className="portf-case">
														<img className="img-fluid" alt="project5" src="images/projects/project-5.jpg"/>
													</div>
												</div>
												<div className="col-md-4">
													<div className="card-portf-case">
														<div className="mini-tags">
															<span>Сайт компании</span>
														</div>
														<div className="h2-zg">
															<h2>Сана</h2>
															<p>Интернет-магазин электроники</p>
														</div>
														<a className="button-case"  target="_blanc" href="http://sana05.ru">Перейти</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
								</div>
							</div>
						</div>
					</>
  );
};

export default Portfolio;
